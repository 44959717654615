export default {
  address: '高雄市楠梓區大學60街96號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14719.687650913016!2d120.2748035!3d22.7311436!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2c9eeeaadf780327!2z5aSn5a245aKFIOaOpeW-heS4reW_gw!5e0!3m2!1szh-TW!2stw!4v1606388846453!5m2!1szh-TW!2stw',
  phone: '07-366-3888',
  fbLink: 'https://www.facebook.com/jiashangjiathebestvilla/',
  fbMessage: 'https://m.me/jiashangjiathebestvilla/',
  googleLink: 'https://goo.gl/maps/eGK6jNUQEGYjCi9KA',
  caseName: '大學墅',

  houseInfos: [
    ['地址', '高雄市楠梓區大學60街96號'],
    ['電話', '07-366-3888'],
    ['投資興建', '佳上佳地產'],
    ['廣告代銷', '上揚國際建築團隊'],
    ['設計團隊', '原東聯合建築師事務所'],
    ['營造公司', '豐禾營建'],
    ['基地面積', '1186坪'],
    ['戶數規劃', '59戶'],
  ],

  gtmCode: ['PFLMPX5', 'M7BQK22', 'T7DKJ2M'], // 可放置多個
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
